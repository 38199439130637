.noVouchersContainer {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  margin-top: 1rem;

  & .noVouchersTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3E444A;
    margin-bottom: 10px;
  }

  & .noVouchersText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #68717B;
  }
}




// // table {
// //   width: 100%;
// //   margin-top: 24px;
// //   border-collapse: collapse;
// //   border-radius: 20px;

// //   tr:nth-child(odd) {
// //     background-color: #F5FAFF;
// //   }

// //   td {
// //     padding: 8px 12px;
// //     font-size: 14px;
// //     line-height: 20px;
// //     color: #3E444A;
// //     font-weight: 500;

// //     span {
// //       font-size: 14px;
// //       font-weight: 500;
// //     }
// //   }

// //   thead {
// //     border-radius: 20px;

// //     td {
// //       background: #ECF2F7;
// //       font-weight: 600;
// //       height: 36px;

// //       &:first-child {
// //         border-radius: 12px 0 0 0;
// //       }

// //       &:last-child {
// //         border-radius: 0 12px 0 0;
// //       }
// //     }
// //   }

// //   tbody {
// //     td {
// //       height: 56px;
// //       border: none;
// //       padding: 8px 10px;
// //       &:last-child{
// //         padding: 8px 0;
// //       }
// //     }
// //   }
// // }

// .MuiTableHead-root{
//   border-radius: 10px;
//   background: red !important;
// }
