.nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;

  &_line {
    background: #ECF2F7;
    border-radius: 2px;
    height: 4px;
  }

  &_title {
    margin-top: 12px;
    color: black;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &_text {
    color: black;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    max-width: 256px;
    width: 100%;
  }

  &_activeLink {
    h5 {
      color: #0090D1;
    }

    div {
      background-color: #0090D1;
    }
  }
}